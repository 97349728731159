.about-me {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease 0s 1 forwards;
}
.about-me-title {
    margin: 0px 0px 20px 0px;
    font-weight: 500;
    color: var(--orange-red);
}
.about-me-cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.about-me-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-me-card-title {
    margin: 0px 0px 10px 0px;
    padding: 0;
    font-weight: 500;
    color: var(--prussian-blue);
}
.about-me-card {
    margin-bottom: 15px;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--honeydew-opaque);
    border-radius: 18px;
    box-shadow: 0px 0px 10px 2px var(--shadow-color);
}

@media only screen and (max-width: 479px) {
    .about-me {
        padding: 30px 0px;
        height: 100%;
    }
    .about-me-container {
        margin-top: 70px;
    }
    .about-me-title {
        font-size: 32px;
    }
    .about-me-cards-container {
        flex-direction: column;
    }
    .top {
        width: 320px;
    }
    .bottom {
        width: 320px;
        margin-top: 20px;
    }
    .about-me-card-title {
        margin-top: 10px;
        font-size: 22px;
    }
    .card-tall {
        width: 280px;
        height: 340px;
        padding: 5px 10px;
    }
    .card-wide {
        width: 200px;
        height: 60px;
        padding: 15px 20px;
    }
}
@media only screen and (min-width: 480px) {
    .about-me {
        height: 100%;
    }
    .about-me-container {
        padding: 40px 0px;
        margin-top: 100px;
    }
    .about-me-title {
        font-size: 40px;
    }
    .about-me-cards-container {
        flex-direction: column;
    }
    .top {
        width: 450px;
    }
    .bottom {
        width: 450px;
        margin-top: 20px;
    }
    .about-me-card-title {
        margin-top: 20px;
        font-size: 26px;
    }
    .card-tall {
        width: 400px;
        height: 300px;
        padding: 15px 20px;
    }
    .card-wide {
        width: 260px;
        height: 60px;
        padding: 15px 20px;
    }
}
@media only screen and (min-width: 768px) {
    .about-me-container {
        padding: 100px 0px;
        margin-left: 180px;
        margin-top: 0px;
    }
    .about-me-title {
        font-size: 42px;
    }
    .about-me-cards-container {
        flex-direction: column;
    }
    .top {
        width: 480px;
    }
    .card-wide {
        padding: 30px 10px;
    }
    .bottom {
        width: 480px;
        margin-top: 30px;
    }
    .about-me-card-title {
        font-size: 30px;
    }
    .card-tall {
        width: 430px;
        height: 330px;
        padding: 15px 20px;
    }
    .card-wide {
        width: 300px;
        height: 70px;
        padding: 15px 20px;
    }
}
@media only screen and (min-width: 992px) {
    .about-me-container {
        margin-left: 200px;
    }
    .about-me-title {
        font-size: 48px;
    }
    .card-wide {
        padding: 40px 0px;
    }
    .about-me-card-title {
        font-size: 32px;
    }
    .card-tall {
        width: 440px;
        height: 360px;
        padding: 20px 30px;
    }
    .card-wide {
        width: 375px;
        height: 30px;
    }
}
@media only screen and (min-width: 1200px) {
    .about-me {
        height: 100vh;
        padding: 0px;
    }
    .about-me-cards-container {
        flex-direction: row;
    }
    .bottom {
        margin-top: 20px;
        width: 50%;
    }
    .card-tall {
        width: 400px;
        height: 360px;
        padding: 20px 30px;
    }
    .card-wide {
        width: 375px;
        height: 30px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
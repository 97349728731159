.project-tech {
	display: flex;
	flex-direction: column;
}
.project-tech-title {
	font-weight: 500;
	color: var(--prussian-blue);
}
.tech-title-underline {
    font-size: 1px;
    color: rgba(0, 0, 0, 0);
    line-height: 1px;
    background-color: grey;
    margin-top: 3px;
    margin-bottom: 0px;
}
.project-tech-info {
	font-weight: 500;
	color: var(--black-coral);
}

@media only screen and (max-width: 479px) {
	.project-tech {
		width: 70%;
		align-items: center;
	}
	.project-tech-title {
		margin: 5px 0px 0px 0px;
		font-size: 15px;
	}
	.tech-title-underline {
		width: 50%;
	}
	.project-tech-info {
		text-align: center;
		justify-content: center;
		margin-top: 6px;
		font-size: 13px;
	}
	.project-details {
		width: 70%;
	}
	.project-detail {
		margin: 7px 0px;
		font-size: 13px;
		text-align: center;
	}
}
@media only screen and (min-width: 480px) {
	.project-tech {
		width: 70%;
		align-items: center;
	}
	.project-tech-title {
		margin: 5px 0px 0px 0px;
		font-size: 17px;
	}
	.tech-title-underline {
		width: 40%;
	}
	.project-tech-info {
		text-align: center;
		justify-content: center;
		margin-top: 10px;
		font-size: 15px;
	}
	.project-details {
		width: 70%;
	}
	.project-detail {
		margin: 5px 0px;
		font-size: 14px;
	}
}
@media only screen and (min-width: 768px) {
	.project-tech-title {
		margin: 10px 0px 0px 0px;
		font-size: 18px;
	}
	.tech-title-underline {
		width: 40%;
	}
	.project-tech-info {
		text-align: center;
		justify-content: center;
		font-size: 16px;
	}
	.project-detail {
		margin: 10px 0px;
		font-size: 16px;
	}
}
@media only screen and (min-width: 992px) {
	.project-tech {
		width: 260px;
		align-items: flex-start;
	}
	.project-tech-title {
		margin: 20px 0px 0px 0px;
		font-size: 19px;
	}
	.tech-title-underline {
		width: 44%;
	}
	.project-tech-info {
		margin-top: 10px;
		font-size: 17px;
		text-align: left;
	}
	.project-details {
		width: 300px;
		height: 270px;
	}
	.project-detail {
		margin: 10px 0px 5px 0px;
		font-size: 16px;
		text-align: left;
	}
}
@media only screen and (min-width: 1200px) {
	.project-tech {
		width: 300px;
	}
	.tech-title-underline {
		width: 38%;
	}
	.project-details {
		width: 325px;
	}
}
.footer {
    position: fixed;
    bottom: 0%;
    width: 120vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background-color: var(--black-coral);
    border-top: 1px solid var(--shadow-color);
    box-shadow: 0px 0px 12px 1px var(--shadow-color);
}
.footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 479px) {
    .footer-copyright {
        font-size: 14px;
    }
}
@media only screen and (min-width: 480px) {
    .footer-copyright {
        font-size: 15px;
    }
}
@media only screen and (min-width: 768px) {
    .footer-copyright {
        font-size: 16px;
    }
}
@media only screen and (min-width: 992px) {
    .footer-copyright {
        font-size: 17px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,600;1,700&display=swap');

html {
  --prussian-blue: rgb(29, 53, 87);
  --black-coral: rgb(73, 88, 103);
  --blue-yonder: rgb(87, 115, 153);
  --honeydew:rgb(241, 250, 238);
  --orange-red: rgb(254, 95, 85);
  --honeydew-opaque: rgba(241, 250, 238, 0.5);
  --blue-yonder-opaque: rgba(87, 115, 153, 0.5);
  --shadow-color: rgba(0,0,0,.25);
}

body {
  margin: 0;
  background: var(--black-coral);
  color: var(--honeydew);
  font-family: 'Montserrat', sans-serif;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

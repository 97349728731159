.mobile-nav {
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}
.mobile-nav-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-yonder);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    z-index: 1;
}
.mobile-nav-btn-img {
    transition: filter 200ms;
}
.mobile-nav-btn-img:active {
    filter: invert(100%) grayscale(100%);
}
.mobile-nav-dropdown {
    width: 100%;
    background-color: var(--blue-yonder-opaque);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
}
.mobile-nav-close {
    width: 100%;
    background-color: var(--blue-yonder-opaque);
}
.mobile-nav-links {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.mobile-nav-link-list {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.links-fade-in {
    animation: linksFadeIn .5s ease-in 0s 1 forwards;
}
.links-fade-out {
    animation: linksFadeOut .5s ease-in 0s 1 forwards;
}
.mobile-nav-link {
    list-style-type: none;
}
.mobile-link {
    color: var(--honeydew);
    text-decoration: none;
    font-weight: 500;
}
.mobile-link:hover {
    color: var(--prussian-blue);
    font-weight: 600;
}
.mobile-link:active {
    color: var(--orange-red);

}
.active-mobile-link {
    color: var(--orange-red);
}

@media only screen and (max-width: 479px){
    .mobile-nav-bar {
        animation: mobileLoadInXSmall 1s ease-in 0s 1 forwards;
    }
    .mobile-nav-logo {
        margin-left: 30px;
    }
    .mobile-nav-logo-img {
        width: 70px;
    }
    .mobile-nav-dropdown-btn {
        margin-right: 30px;
    }
    .mobile-nav-btn-img {
        width: 20px;
        height: 20px;
    }
    .mobile-nav-dropdown {
        animation: dropDownXSmall .5s ease-in 0s 1 forwards;
    }
    .mobile-nav-close {
        animation: closeXSmall .5s ease-in 0s 1 forwards;
    }
    .mobile-nav-link-list {
        padding-right: 10px;
    }
    .mobile-nav-link {
        padding: 15px;
    }
    .mobile-link {
        font-size: 14px;
    }
}
@media only screen and (min-width: 480px){
    .mobile-nav-bar {
        animation: mobileLoadInSmall 1s ease-in 0s 1 forwards;
    }
    .mobile-nav-logo {
        margin-left: 50px;
    }
    .mobile-nav-logo-img {
        width: 100px;
    }
    .mobile-nav-dropdown-btn {
        margin-right: 50px;
    }
    .mobile-nav-btn-img {
        width: 25px;
        height: 25px;
    }
    .mobile-nav-dropdown {
        animation: dropDownSmall .5s ease-in 0s 1 forwards;
    }
    .mobile-nav-close {
        animation: closeSmall .5s ease-in 0s 1 forwards;
    }
    .mobile-nav-link-list {
        padding-right: 20px;
    }
    .mobile-nav-link {
        padding: 18px;
    }
    .mobile-link {
        font-size: 16px;
    }
}

@keyframes mobileLoadInXSmall {
    0% {
        height: 0px;
    }
    100% {
        height: 70px;
    }
}
@keyframes mobileLoadInSmall {
    0% {
        height: 0px;
    }
    100% {
        height: 100px;
    }
}
@keyframes dropDownXSmall {
    0% {
        height: 0px;
    }
    100% {
        height: 200px;
    }
}
@keyframes dropDownSmall {
    0% {
        height: 0px;
    }
    100% {
        height: 220px;
    }
}
@keyframes closeXSmall {
    0% {
        height: 200px;
    }
    100% {
        height: 0px;
    }
}
@keyframes closeSmall {
    0% {
        height: 220px;
    }
    100% {
        height: 0px;
    }
}
@keyframes linksFadeIn{
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes linksFadeOut{
    0% {
        opacity: 1;
    }
    45% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}
.form-inputs {
    display: flex;
    justify-content: space-between;
}
.form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.form-label {
    font-weight: 500;
}
.form-input {
    padding: 2px 5px 2px 5px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px var(--shadow-color);
    background-color: var(--honeydew-opaque);
    font-family: montserrat;
    font-weight: 500;
    color: var(--prussian-blue);
}
.form-textarea-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.form-textarea {
    padding: 2px 5px 2px 5px;
    font-family: montserrat;
    font-weight: 500;
    color: var(--prussian-blue);
    resize: none;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px var(--shadow-color);
    background-color: var(--honeydew-opaque);
}
.form-submit-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

input:focus, textarea:focus {
    outline: 2px solid var(--orange-red);

}

@media only screen and (max-width: 479px) {
    .form-inputs {
        flex-direction: column;
    }
    .form-label {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .form-input {
        height: 32px;
        margin-bottom: 6px;
        font-size: 13px;
    }
    .name-input, .email-input, .subject-input {
        width: 300px;
    }
    .form-textarea {
        width: 300px;
        height: 120px;
    }
    .form-submit-message {
        height: 40px;
    }
}
@media only screen and (min-width: 480px) {
    .form-inputs {
        flex-direction: column;
    }
    .form-label {
        font-size: 15px;
        margin-bottom: 6px;
    }
    .form-input {
        height: 34px;
        margin-bottom: 8px;
        font-size: 14px;
    }
    .name-input, .email-input, .subject-input {
        width: 410px;
    }
    .form-textarea {
        width: 410px;
        height: 120px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .form-submit-message {
        height: 50px;
    }
}
@media only screen and (min-width: 768px) {
    .form-label {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .form-input {
        height: 38px;
        margin-bottom: 12px;
        font-size: 15px;
    }
    .name-input, .email-input, .subject-input {
        width: 480px;
    }
    .form-textarea {
        width: 480px;
        height: 160px;
        font-size: 15px;
        margin-bottom: 12px;
    }
    .form-submit-message {
        height: 55px;
    }
}
@media only screen and (min-width: 992px) {
    .form-inputs {
        flex-direction: row;
    }
    .form-label {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .form-input {
        height: 40px;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .name-input, .email-input {
        width: 320px;
    }
    .subject-input {
        width: 690px;
    }
    .form-textarea {
        width: 690px;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .form-submit-message {
        height: 60px;
    }
}
@media only screen and (min-width: 1200px) {
    .name-input, .email-input {
        width: 420px;
    }
    .subject-input {
        width: 889px;
    }
    .form-textarea {
        width: 890px;
    }
}
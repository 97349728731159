.slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slide-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.slide-content-left {
    display: flex;
    flex-direction: column;
}
.slide-title {
    margin: 0;
    font-weight: 500;
    color: var(--prussian-blue);
}
.slide-title-underline {
    font-size: 1px;
    color: rgba(0, 0, 0, 0);
    line-height: 1px;
    background-color: grey;
    margin-top: 3px;
    margin-bottom: 0px;
}
.slide-details {

    display: flex;
    justify-content: center;
}
.slide-content-right {
    display: flex;
    flex-direction: column;
}
.slide-img-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-img {
    height: auto;
    border-radius: 16px;
    filter: grayscale(30%);
    box-shadow: 0px 0px 6px 2px var(--shadow-color);
}
.slide-link-btn {
    font-family: montserrat;
    font-weight: 500;
    color: var(--honeydew);
    background-color: var(--blue-yonder);
    box-shadow: 0px 0px 5px 1px var(--shadow-color);
    border-radius: 12px;
    border: none;
}
.slide-link-btn:hover {
    background-color: var(--prussian-blue);
    cursor: pointer;
}
.slide-link-btn:active {
    background-color: var(--orange-red);
}

@media only screen and (max-width: 479px) {
    .slide-content {
        height: 80%;
        flex-direction: column-reverse;
    }
    .slide-content-left {
        margin-top: 10px;
        width: 100%;
        height: 270px;
        align-items: center;
    }
    .slide-title {
        font-size: 24px;
    }
    .slide-title-underline {
        width: 65%;
    }
    .slide-content-right {
        width: 100%;
        height: 300px;
        justify-content: center;
        align-items: center;
    }
    .slide-img-container{
        height: 180px;
    }
    .slide-img {
        width: 250px;
        margin: 5px 0px;
    }
    .slide-info {
        margin-top: 10px;
        height: 90px;
        display: flex;
        justify-content: center;
    }
    .slide-link-btn {
        width: 70px;
        height: 25px;
        margin: 20px 5px;
        font-size: 12px;
    }
}
@media only screen and (min-width: 480px) {
    .slide-content {
        height: 100%;
        margin-top: 20px;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .slide-content-left {
        width: 100%;
        height: 240px;;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
    }
    .slide-title {
        font-size: 26px;
    }
    .slide-title-underline {
        width: 60%;
    }
    .slide-content-right {
        width: 100%;
        height: 350px;
        justify-content: center;
        align-items: center;
    }
    .slide-img-container{
        height: 260px;
    }
    .slide-img {
        width: 380px;
        margin: 10px 0px;
    }
    .slide-info {
        height: 90px;
        display: flex;
        justify-content: center;
    }
    .slide-link-btn {
        width: 75px;
        height: 28px;
        margin: 20px 5px;
        font-size: 13px;
    }
}
@media only screen and (min-width: 768px) {
    .slide-content {
        margin-top: 20px;
    }
    .slide-content-left {
        width: 100%;
        height: 300px;
        margin-top: 10px;
    }
    .slide-title {
        font-size: 28px;
    }
    .slide-title-underline {
        width: 70%;
    }
    .slide-content-right {
        width: 100%;
        height: 500px;
        justify-content: center;
        align-items: center;
    }
    .slide-img-container{
        height: 300px;
    }
    .slide-img {
        width: 440px;
        margin: 10px 0px;
    }
    .slide-info {
        margin-top: 10px;
        height: 100px;
    }
    .slide-link-btn {
        width: 80px;
        height: 30px;
        margin: 20px 5px;
        font-size: 14px;
    }
}
@media only screen and (min-width: 992px) {
    .slide-content {
        height: 70%;
        margin-top: 20px;
        flex-direction: row;
    }
    .slide-content-left {
        width: 300px;
        margin-right: 20px;
        justify-content: flex-start;
    }
    .slide-title {
        font-size: 28px;
    }
    .slide-title-underline {
        width: 100%;
        margin-left: 0%;        
    }
    .slide-content-right {
        margin-left: 10px;
        width: 325px;
        height: 340px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .slide-img-container{
        height: 200px;
    }
    .slide-img {
        width: 325px;
    }
}
@media only screen and (min-width: 1200px) {
    .slide-content-left {
        width: 325px;
    }
    .slide-content-right {
        margin-left: 10px;
        width: 350px;
        height: 350px;
    }
    .slide-img {
        width: 350px;
    }
}
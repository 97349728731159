.contact-social {
    width: 80%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.contact-social-link {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-social-icon {
	width: 40px;
	height: auto;
	box-shadow: 0px 0px 10px 2px var(--shadow-color);
}
.round-icon {
	border-radius: 50%;
}
.square-icon {
	border-radius: 8px;
}
.contact-social-icon:active {
	filter: hue-rotate(200deg) grayscale(75%);
}

@media only screen and (max-width: 479px) {
	.contact-social-link {
		width: 35px;
		height: 35px;
		padding: 30px 2px;
	}
	.contact-social-icon {
		width: 30px;
	}
	.contact-social-icon:hover {
		width: 35px;
	}
}
@media only screen and (min-width: 480px) {
	.contact-social-link {
		width: 40px;
		height: 40px;
		padding: 10px 0px;
	}
	.contact-social-icon {
		width: 30px;
	}
	.contact-social-icon:hover {
		width: 35px;
	}
}
@media only screen and (min-width: 768px) {
	.contact-social-link {
		width: 45px;
		height: 45px;
		padding: 10px 0px;
	}
	.contact-social-icon {
		width: 35px;
	}
	.contact-social-icon:hover {
		width: 40px;
	}
}
@media only screen and (min-width: 992px) {
	.contact-social-link {
		width: 55px;
		height: 55px;
	}
	.contact-social-icon {
		width: 40px;
	}
	.contact-social-icon:hover {
		width: 45px;
	}
}
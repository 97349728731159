.slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--honeydew-opaque);
    box-shadow: 0px 0px 15px 2px var(--shadow-color);
    border-radius: 18px;
}
.slide-btns {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.slide-btn {
    font-weight: 500;
    color: var(--black-coral);
    background-color: transparent;
    border: none;
}
.slide-btn:hover {
    cursor: pointer;
    font-size: 48px;
    color: var(--prussian-blue);
}
.slide-btn:active {
    color: var(--orange-red);
}
.dot {
    border: none;
    border-radius: 50%;
    background-color: var(--honeydew);
    box-shadow: 0px 0px 4px 1px var(--shadow-color);
}
.dot:hover {
    cursor: pointer;
}
.active {
    background-color: var(--prussian-blue);
}

@media only screen and (max-width: 479px) {
    .slider-container {
        width: 300px;
        height: 740px;
        margin-bottom: 50px;
    }
    .slide-btns {
        width: 300px;
    }
    .slide-btn {
        width: 30px;
        height: 30px;
        font-size: 24px;
    }
    .slide-btn:hover {
        font-size: 30px;
    }
    .dots-container {
        width: 100px;
        height: 35px;
    }
    .dot {
        width: 10px;
        height: 10px;
        margin: 0px 6px;
    }
    .active {
        width: 12px;
        height: 12px;
    }
}
@media only screen and (min-width: 480px) {
    .slider-container {
        width: 450px;
        height: 800px;
        margin-bottom: 80px;
    }
    .slide-btns {
        width: 450px;
    }
    .slide-btn {
        width: 40px;
        height: 40px;
        font-size: 26px;
    }
    .slide-btn:hover {
        font-size: 30px;
    }
    .dots-container {
        width: 90px;
        height: 40px;
    }
    .dot {
        width: 11px;
        height: 11px;
        margin: 0px 7px;
    }
    .active {
        width: 13px;
        height: 13px;
    }
}
@media only screen and (min-width: 768px) {
    .slider-container {
        width: 550px;
        height: 950px;
        margin-bottom: 0px;
    }
    .slide-btns {
        width: 550px;
    }
    .slide-btn {
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
    .slide-btn:hover {
        font-size: 36px;
    }
    .dots-container {
        width: 100px;
        height: 50px;
    }
    .dot {
        width: 12px;
        height: 12px;
        margin: 0px 8px;
    }
    .active {
        width: 15px;
        height: 15px;
    }
}
@media only screen and (min-width: 992px) {
    .slider-container {
        width: 780px;
        height: 500px;
    }
    .slide-btns {
        width: 780px;
    }
    .slide-btn {
        width: 55px;
        height: 55px;
        font-size: 38px;
    }
    .slide-btn:hover {
        font-size: 46px;
    }
    .dots-container {
        width: 120px;
        height: 50px;
    }
    .dot {
        width: 15px;
        height: 15px;
        margin: 0px 10px;
    }
    .active {
        width: 18px;
        height: 18px;
    }
}
@media only screen and (min-width: 1200px) {
    .slider-container {
        width: 850px;
        height: 500px;
    }
    .slide-btns {
        width: 850px;
    }
    .slide-btn {
        width: 70px;
        height: 70px;
        font-size: 40px;
    }
    .slide-btn:hover {
        font-size: 48px;
    }
}
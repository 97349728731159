.main {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 3s ease 0s 1 forwards;
}
.main-avatar {
    border-radius: 50%;
    margin-right: 30px;
    box-shadow: 0px 0px 10px 2px var(--shadow-color);
}
.main-intro {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}
.main-intro-title {
    margin: 0;
    padding: 0;
    font-weight: 500;
}
.main-intro-title-bold {
    color: var(--orange-red);
    font-style: italic;
    font-weight: 700;
}
.main-intro-tagline {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 479px) and (orientation: portrait) {
    .main-content {
        margin-top: 70px;
        flex-direction: column;
    }
    .main-avatar {
        width: 180px;
        margin-bottom: 25px;
    }
    .main-intro-title {
        font-size: 24px;
    }
    .main-intro-title-bold {
        font-size: 26px;
    }
    .main-intro-tagline {
        font-size: 14px;
        width: 260px;
    }
}
@media only screen and (max-height: 479px) and (orientation: landscape) {
    .main-content {
        height: 600px;
        flex-direction: row;
    }
    .main-avatar {
        max-width: 200px;
        margin-top: 140px;
    }
    .main-intro-tagline {
        max-width: 320px;
    }
}

@media only screen and (min-width: 480px) {
    .main-content {
        margin-top: 100px;
        flex-direction: column;
    }
    .main-avatar {
        width: 200px;
        margin-bottom: 30px;
    }
    .main-intro-title {
        font-size: 30px;
    }
    .main-intro-title-bold {
        font-size: 34px;
    }
    .main-intro-tagline {
        font-size: 16px;
        width: 320px;
    }
}
@media only screen and (min-width: 768px) {
    .main-content {
        margin-left: 180px;
        margin-top: 0px;
        flex-direction: column;
    }
    .main-avatar {
        width: 225px;
        margin-bottom: 40px;
    }
    .main-intro-title {
        font-size: 34px;
    }
    .main-intro-title-bold {
        font-size: 38px;
    }
    .main-intro-tagline {
        font-size: 18px;
        width: 330px;
    }
}
@media only screen and (min-width: 992px) {
    .main-content {
        margin-left: 200px;
        flex-direction: row;
    }
    .main-avatar {
        width: 250px;
    }
    .main-intro-title {
        font-size: 44px;
    }
    .main-intro-title-bold {
        font-size: 46px;
    }
    .main-intro-tagline {
        font-size: 20px;
        width: 350px;
    }
}
@media only screen and (min-width: 1200px) {
    .main-content {
        margin-left: 240px;
    }
    .main-avatar {
        width: 300px;
    }
    .main-intro-title {
        font-size: 48px;
    }
    .main-intro-title-bold {
        font-size: 50px;
    }
    .main-intro-tagline {
        font-size: 22px;
        width: 400px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
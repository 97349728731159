.docs-btn {
    background-color: var(--prussian-blue);
    font-family: montserrat;
    font-weight: 600;
    color: var(--honey-dew);
    border: none;
    border-radius: 14px;
    margin: 0px 20px;
}
.submit-btn {
    background-color: var(--prussian-blue);
    font-family: montserrat;
    font-weight: 600;
    color: var(--honey-dew);
    border: none;
    border-radius: 14px;
}
.mobile-nav-btn {
    background-color: transparent;
    border: none;
    border-radius: 12px;
    transition: background-color 200ms, box-shadow 200ms;
}
.docs-btn, .submit-btn {
    box-shadow: 0px 0px 10px 2px var(--shadow-color);
}
.docs-btn:hover, .submit-btn:hover {
    background-color: var(--blue-yonder);
    cursor: pointer;
}
.docs-btn:active, .submit-btn:active {
    background-color: var(--orange-red);
}
.mobile-nav-btn:hover {
    cursor: pointer;
    background-color: var(--honeydew-opaque);
    box-shadow: 0px 0px 6px 2px var(--shadow-color);
}
.mobile-nav-btn:active {
    background-color: var(--orange-red);
}
@media only screen and (max-width: 479px) {
    .mobile-nav-btn {
        width: 40px;
        height: 40px;
    }
    .docs-btn {
        margin: 0px 10px;
        width: 90px;
        height: 35px;
        font-size: 12px;
    }
    .submit-btn {
        margin: 10px 0px;
        width: 100px;
        height: 35px;
        font-size: 13px;
    }
}
@media only screen and (min-width: 480px) {
    .mobile-nav-btn {
        width: 50px;
        height: 50px;
    }
    .docs-btn {
        margin: 0px 10px;
        width: 110px;
        height: 40px;
        font-size: 14px;
    }
    .submit-btn {
        margin: 10px 0px;
        width: 110px;
        height: 40px;
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px) {
    .docs-btn {
        margin: 0px 15px;
        width: 130px;
        height: 45px;
        font-size: 15px;
    }
    .submit-btn {
        width: 140px;
        height: 55px;
        font-size: 15px;
        margin: 12px 0px;
    }
}
@media only screen and (min-width: 992px) {
    .docs-btn {
        margin: 0px 20px;
        width: 140px;
        height: 50px;
        font-size: 16px;
    }
    .submit-btn {
        width: 160px;
        height: 60px;
        font-size: 16px;
        margin: 15px 0px;
    }
}
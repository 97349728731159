.projects {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease 0s 1 forwards;
}
.projects-title {
    margin: 0;
    font-size: 48px;
    font-weight: 500;
    color: var(--orange-red);
}

@media only screen and (max-width: 479px) {
    .projects {
        height: 100%;
    }
    .projects-container {
        margin-top: 70px;
    }
    .projects-title {
        font-size: 32px;
        margin: 30px 0px;
    }
}
@media only screen and (min-width: 480px) {
    .projects {
        height: 100%;
    }
    .projects-container {
        margin-top: 100px;
    }
    .projects-title {
        font-size: 38px;
        margin: 40px 0px;
    }
}
@media only screen and (min-width: 768px) {
    .projects-container {
        padding: 100px 0px;
        margin-top: 0px;
        margin-left: 180px;
    }
    .projects-title {
        font-size: 42px;
        margin: 0px 0px 50px 0px;
    }
}
@media only screen and (min-width: 996px) {
    .projects {
        height: 100vh;
    }
    .projects-container {
        margin-left: 200px;
    }
    .projects-title {
        font-size: 48px;
    }
}


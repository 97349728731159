.router {
    display: flex;
    flex-direction: row;
}
.side-bar {
    height: 100vh;
    position: fixed;
    background-color: var(--blue-yonder);
    box-shadow: 2px 0px 3px 1px rgba(0, 0, 0, 0.25);
    z-index: 1;
}
.fade-in {
    animation: fadeInOnPageLoad 1.25s ease 0s 1 forwards;
}
.side-bar-hover {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: fixed;
    background-color: var(--blue-yonder);
    box-shadow: 2px 0px 3px 1px rgba(0, 0, 0, 0.25);
    animation: hideOnPageLoad 1.25s linear 0s 1 forwards;
    transition: width .5s;
}
.side-bar-logo {
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    position: fixed;
    padding-top: 60px;
}
.side-bar-logo-img {
    height: auto
}
.side-bar-links {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.side-bar-link-list {
    position: relative;
    margin: 0;
    padding: 0;
}
.side-bar-link {
    list-style-type: none;
    padding: 20px;
}
.link {
    color: var(--honeydew);
    text-decoration: none;
    font-weight: 500;
}
.link:hover {
    color: var(--prussian-blue);
    font-weight: 600;
}
.link:active {
    color: var(--orange-red);
}
.active-link {
    color: var(--orange-red);
    font-weight: 600;

}
@media only screen and (max-height: 479px) and (orientation: landscape){
    .side-bar {
        animation: widenOnPageLoadTablet 1s ease-in 0s 1 forwards;
    }
    .side-bar-logo-img {
        display: none;
    }
}
@media only screen and (min-height: 479px) and (orientation: portrait){
    .side-bar {
        animation: widenOnPageLoadTablet 1s ease-in 0s 1 forwards;
    }
    .side-bar-hover {
        width: 180px;
    }
    .side-bar-hover:hover {
        width: 230px;
    }
    .side-bar-logo-img {
        width: 110px;
    }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
    .side-bar {
        animation: widenOnPageLoadTablet 1s ease-in 0s 1 forwards;
    }
    .side-bar-hover {
        width: 180px;
    }
    .side-bar-hover:hover {
        width: 230px;
    }
    .side-bar-logo-img {
        width: 110px;
    }
    .link {
        font-size: 17px;
    }
}

@media only screen and (min-width: 992px) {
    .side-bar {
        animation: widenOnPageLoadDesktop 1s ease-in 0s 1 forwards;
    }
    .side-bar-hover {
        width: 200px;
    }
    .side-bar-hover:hover {
        width: 260px;
    }
    .side-bar-logo-img {
        width: 120px;
    }
    .link {
        font-size: 19px;
    }
}
@media only screen and (min-width: 1200px) {
    .side-bar {
        animation: widenOnPageLoadDesktopLarge 1s ease-in 0s 1 forwards;
    }
    .side-bar-hover {
        width: 240px;
    }
    .side-bar-hover:hover {
        width: 280px;
    }
    .side-bar-logo-img {
        width: 140px;
    }
    .link {
        font-size: 21px;
    }
}

@keyframes widenOnPageLoadDesktopLarge {
    0% {
        width: 0px;
    }
    100% {
        width: 240px;
        box-shadow: none;
    }
}
@keyframes widenOnPageLoadDesktop {
    0% {
        width: 0px;
    }
    100% {
        width: 200px;
        box-shadow: none;
    }
}
@keyframes widenOnPageLoadTablet {
    0% {
        width: 0px;
    }
    100% {
        width: 180px;
        box-shadow: none;
    }
}
@keyframes fadeInOnPageLoad {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1
    }
}
@keyframes hideOnPageLoad {
    0% {
        opacity: 0%;
    }
    75% {
        opacity: 0%
    }
    100% {
        opacity: 100%
    }
}
.skills {
    display: flex;
    justify-content: center;
    align-content: center;
}
.skills-grid {
    display: grid;
}
.skill-container {
    width: auto;
}
.skill-img {
    width: auto;
}
.skill-name{
    margin: 0;
}
.background-content {
    font-weight: 400;
}
.documents-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 470px) {
    .skills-grid {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto auto;
        column-gap: 25px;
        row-gap: 15px;
    }
    .skill-container {
        height: 65px;
    }
    .skill-img {
        height: 70%;
    }
    .skill-name{
        font-size: 12px;
    }
    .background-content {
        font-size: 13px;
        padding: 0px 5px
    }
    .documents-container {
        flex-direction: row;
    }
}
@media only screen and (min-width: 480px) {
    .skills-grid {
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto auto auto;
        column-gap: 20px;
        row-gap: 20px;
    }
    .skill-container {
        height: 70px;
    }
    .skill-img {
        height: 70%;
    }
    .skill-name{
        font-size: 13px;
    }
    .background-content {
        font-size: 14px;
        padding: 0px 5px
    }
    .documents-container {
        flex-direction: row;
    }
}
@media only screen and (min-width: 768px) {
    .skills-grid {
        column-gap: 20px;
        row-gap: 20px;
    }
    .skill-container {
        height: 75px;
    }
    .skill-img {
        height: 75%;
    }
    .skill-name{
        font-size: 14px;
    }
    .background-content {
        font-size: 15px;
    }
    .documents-container {
        flex-direction: row;
    }
}
@media only screen and (min-width: 992px) {
    .skills-grid {
        column-gap: 30px;
        row-gap: 30px;
    }
    .skill-container {
        height: 80px;
    }
    .skill-img {
        height: 80%;
    }
    .skill-name{
        font-size: 15px;
    }
    .background-content {
        font-size: 16px;
    }
}
.contact {
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease 0s 1 forwards;
}
.contact-title {
    font-weight: 500;
    color: var(--orange-red);
}

@media only screen and (max-height: 479px) and (orientation: landscape){
    .contact {
        min-height: 800px;
    }
    .contact-container {
        padding: 80px 0px;
    }
}
@media only screen and (max-width: 479px) {
    .contact {
        height: 100vh;
    }
    .contact-container {
        padding: 20px 0px;
        margin-top: 70px;
    }
    .contact-title {
        font-size: 32px;
    }
}
@media only screen and (min-width: 480px) {
    .contact {
        height: 100%;
        margin-top: 100px;
        margin-bottom: 60px;
    }
    .contact-title {
        font-size: 38px;
    }
}
@media only screen and (min-width: 768px) {
    .contact {
        height: 100vh;
        margin-left: 180px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .contact-container {
        margin-bottom: 5vh;
    }
    .contact-title {
        font-size: 42px;
    }
}
@media only screen and (min-width: 992px) {
    .contact {
        margin-left: 200px;
    }
    .contact-title {
        font-size: 48px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.submit-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px var(--shadow-color);
    animation: fadeIn .5s ease 0s 1 forwards;
}
.success {
    background-color: var(--blue-yonder);
}
.error {
    background-color: var(--orange-red);
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 479px) {
    /* .submit-message {
        width: 250px;
        height: 50px;
        font-size: 16px;
    } */
}
@media only screen and (min-width: 480px) {
    .submit-message {
        width: 180px;
        height: 40px;
        font-size: 15px;
    }
}
@media only screen and (min-width: 768px) {
    .submit-message {
        width: 250px;
        height: 50px;
        font-size: 16px;
    }
}
@media only screen and (min-width: 992px) {
    .submit-message {
        width: 300px;
        height: 50px;
        font-size: 18px;
    }
}